const services = {
  authService: {
    development: 'http://localhost:9084/auth/api/v1',
    production: `https://europe-west3-${
      JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG).projectId
    }.cloudfunctions.net/auth/auth/api/v1`
  }
};

export default services;
