import { auth } from '@/plugins/firebase';
import axios, { AxiosRequestConfig, Method } from 'axios';

class RequestError extends Error {
  code: any;
  apiError?: any;

  constructor(code: any, message: string, apiError?: any) {
    super(message);
    this.code = code;
    this.apiError = apiError;
  }
}

interface IRequestOptions {
  header?: any;
  query?: any;
}

export default class RequestService {
  baseUrl: string;
  CORRELATION_ID;

  SERVICE_UNAVAILABLE = 'Der Dienst ist aktuell nicht verfügbar. Bitte versuchen Sie es später noch einmal.';
  SERVICE_ERROR = 'Die Aktion war leider nicht erfolgreich. Bitte versuchen Sie es später noch einmal.';

  constructor(serviceUrl: string, correlationId?: string) {
    this.baseUrl = serviceUrl;
    this.CORRELATION_ID = correlationId;
  }

  async delete(path: string, options?: IRequestOptions) {
    return await this.performRequest(path, 'DELETE', undefined, options);
  }

  async get(path: string, options?: IRequestOptions) {
    return await this.performRequest(path, 'GET', undefined, options);
  }

  async patch(path: string, body: any, options?: IRequestOptions) {
    return await this.performRequest(path, 'PATCH', body, options);
  }

  async post(path: string, body: any, options?: IRequestOptions) {
    return await this.performRequest(path, 'POST', body, options);
  }

  async put(path: string, body: any, options?: IRequestOptions) {
    return await this.performRequest(path, 'PUT', body, options);
  }

  private async performRequest(path: string, method: Method, body?: any, options?: IRequestOptions) {
    try {
      const config: AxiosRequestConfig = {
        method: method,
        url: this.baseUrl + path,
        headers: { ...(await this.getCommonHeaders()), ...options?.header },
        params: options?.query
      };
      if (body) {
        config.data = body;
      }
      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      if (err.response) {
        throw new RequestError(
          err.response.status,
          err.response.data?.message || err.message,
          err.response.data?.error || err.response.data
        );
      } else if (err.request) {
        throw new RequestError(0, this.SERVICE_UNAVAILABLE);
      } else {
        console.log(err);
        throw new RequestError(0, this.SERVICE_ERROR);
      }
    }
  }

  async getToken() {
    return auth.currentUser ? auth.currentUser?.getIdToken() : '';
  }

  async getCommonHeaders() {
    const headers: any = {};
    headers.Authorization = 'Bearer ' + (await this.getToken());
    if (this.CORRELATION_ID) {
      headers['X-Correlation-ID'] = this.CORRELATION_ID;
    }
    return headers;
  }
}
