export interface IPage<T> {
  content: T[];
  numberOfElements: number;
  number: number;
  totalPages: number;
  totalElements: number;
  size: number;
}

export interface IListResponse<T> {
  items: T[];
  itemsPerPage: number;
  page: number;
  total: number;
}

export class ListResponse<T> implements IListResponse<T> {
  items: T[];
  itemsPerPage: number;
  page: number;
  total: number;
  totalPages?: number;

  constructor(items: T[], itemsPerPage: number, page: number, total: number, totalPages?: number) {
    this.items = items;
    this.itemsPerPage = itemsPerPage;
    this.page = page;
    this.total = total;
    this.totalPages = totalPages;
  }

  /**
   * Create a ListResponse from a (Spring framework) Page returned from API.
   * - Type R: Data interface with response item structure
   * - Type T: Class implementation which extends R to be Type of ListResponse items
   * @param page
   * @param ItemFactory
   * @constructor
   */
  public static FromPage<R, T extends R>(page: IPage<R>, ItemFactory: (data: any) => T) {
    return new ListResponse<T>(
      page.content.map(ItemFactory),
      page.size,
      page.number + 1,
      page.totalElements,
      page.totalPages
    );
  }

  public static Empty<T>() {
    return new ListResponse<T>([], 10, 1, 0, 1);
  }

  public map<U>(callbackfn: (_: T) => U) {
    return new ListResponse<U>(this.items.map(callbackfn), this.itemsPerPage, this.page, this.total, this.totalPages);
  }
}
