import RequestService from './RequestService';
import services from './services';
import { ERole } from '@/domain/enums/Role';
import { ListResponse } from '@/domain/models/ListResponse';

interface IUserSearchParams {
  role?: ERole;
  search?: string;
  includeAssigned?: boolean;
}

class AuthService {
  get env(): string {
    return `${process.env.NODE_ENV}` || 'development';
  }

  get serviceUrl(): string {
    const config = services.authService as any;
    return config[this.env];
  }

  constructor() {
    this.requestService = new RequestService(this.serviceUrl);
  }

  requestService: RequestService;

  async searchUsers(searchParams: IUserSearchParams, page: number, itemsPerPage: number) {
    const result = await this.requestService.post('/users/search', searchParams, {
      query: {
        page: page,
        itemsPerPage: itemsPerPage
      }
    });
    return new ListResponse<any>(
      result.content,
      result.itemsPerPage,
      result.page,
      result.totalElements,
      result.totalPages
    );
  }

  async getUser(userId: string) {
    return await this.requestService.get(`/users/${userId}`);
  }

  async getOwnUser() {
    return this.requestService.get('/users/user');
  }

  async getUsers() {
    return await this.requestService.get('/users');
  }

  async getCustomersForUser(uid: string) {
    return await this.requestService.get(`/authorization/customer/${uid}`);
  }

  async getAllRoles() {
    return await this.requestService.get('/users/roles/all');
  }

  async verifyEmail(userId: string) {
    return await this.requestService.put(`/users/${userId}/email`, null);
  }

  async createUser(user: any) {
    return await this.requestService.post('/users', user);
  }

  async createUsers(users: any) {
    return await this.requestService.post('/users', users);
  }

  async updateUser(user: any) {
    return await this.requestService.put(`/users/${user.uid}`, user);
  }

  async sendChangePasswordEvent(user: any) {
    return await this.requestService.get('/users/change-password-event', user);
  }

  async resetPassword(email: string) {
    return await this.requestService.post('/users/reset-password', {
      email: email
    });
  }

  async enableUser(user: any) {
    return await this.requestService.put(`/users/user/${user.uid}/enable`, user);
  }

  async disableUser(user: any) {
    return await this.requestService.put(`/users/user/${user.uid}/disable`, user);
  }

  async deleteUser(uid: string) {
    return await this.requestService.delete(`/users/${uid}`);
  }

  async completeVerification(user: any) {
    return await this.requestService.get('/users/verify', user);
  }

  async getPhoneInfo(actionCode: string) {
    return await this.requestService.post('/users/phoneInfo', {
      actionCode: actionCode
    });
  }

  async resendVerificationEmail(actionCode: string, uid: string) {
    return await this.requestService.post('/users/resend/verification-email', {
      actionCode: actionCode,
      uid: uid
    });
  }

  async resendVerificationEmailWithNewActivationCode(activationCode: string, uid: string) {
    return await this.requestService.post(`/users/resend/verification-email/${uid}`, {
      activationCode: activationCode
    });
  }

  async getUploadFiles() {
    return await this.requestService.get('/storage/documents/user/uploads');
  }

  async uploadFile(files: any[]) {
    return await this.requestService.put('/storage/documents/user/upload', files);
  }

  async deleteFile(fileName: string) {
    return await this.requestService.delete(`/storage/documents/user/upload/${fileName}`);
  }
}

export { AuthService };
export default new AuthService();
